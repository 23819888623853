<template>
  <div class="f2g-tooltip">
    <v-tooltip :right="right" :left="left" :top="top" :bottom="bottom">
      <template #activator="{ on }">
        <span v-on="on">{{ text }}</span>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "f2g-tooltip",
  props: {
    right: { type: Boolean, default: false, required: false },
    left: { type: Boolean, default: false, required: false },
    top: { type: Boolean, default: false, required: false },
    bottom: { type: Boolean, default: false, required: false },
    text: { type: String, default: null, required: true },
    tooltip: { type: String, default: null, required: true },
  },
};
</script>

<style style="scss" scoped></style>
